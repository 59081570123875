import './App.css';

export default function App() {
  return (
<div className="font-narrow items-center h-screen text-center">
<div>
<h1 className="text-6xl">внимание</h1>
<h2 className="text-4xl">очень важно</h2>
<h3 className="text-2xl">сезон тапалок окончен, создателя отправили в армию</h3>
    <a
          href="https://santik.su/"
          className="mt-4 bg-black text-white rounded-lg w-auto text-center p-4 h-8"
          target="_self"
        >
          На сайт создателя ›
        </a>
</div>
</div>
    );
    }
